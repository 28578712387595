<template>
  <div class="container">
    <button class="back-button" @click="$router.go(-1)">
      <i class="fas fa-arrow-left"></i>
    </button>

    <div class="header">
      <h1>
        Быстро добавьте и подключите бота к платежной системе, чтобы начать свой
        бизнес или увеличить продажи!
      </h1>

      <!-- Добавленная стрелка вниз -->
      <div class="scroll-down-arrow" @click="scrollToContent">
        <i class="fas fa-chevron-down"></i>
      </div>
    </div>

    <div ref="stepsIntro" class="steps-intro">
      <h2>Всего 3 шага, чтобы подключить наш сервис</h2>
    </div>

    <div class="features">
      <div class="feature">
        <h2>Шаг 1</h2>
        <p>
          Передайте нам информацию о ваших продуктах, а наш менеджер наполнит
          телеграм-бота.
        </p>
        <img alt="Шаг 1" src="@/static/features/step1-image.jpg" />
      </div>
      <div class="feature">
        <h2>Шаг 2</h2>
        <p>
          Передайте информацию о вашем магазине в YooKassa, после чего бот
          автоматически интегрирует её.
        </p>
        <img alt="Шаг 2" src="@/static/features/step2-image.png" />
      </div>
      <div class="feature">
        <h2>Шаг 3</h2>
        <p>
          Добавьте бота в свой чат и прикрепите кнопку с переходом в
          мини-приложение телеграма. Мы вам поможем!
        </p>
        <img alt="Шаг 3" src="@/static/features/step3-image.png" />
      </div>

      <!-- Выделенный шаг с постоянной радужной обводкой и без эффектов при наведении -->
      <div class="feature highlight">
        <h2>И это всё! 🎉🎉🎉</h2>
        <p>Теперь всё настроено, и вы можете пользоваться нашим сервисом!</p>
      </div>
    </div>

    <div class="future-payments">
      <h2>Скоро подключим новые платежные системы</h2>
      <div class="payment-logos">
        <img alt="Платежная система Мир" src="@/static/features/mir.png" />
        <img alt="Платежная система СБП" src="@/static/features/sbp.png" />
        <img
          alt="Платежная система Tinkoff Pay"
          src="@/static/features/tpay.jpg"
        />
        <img alt="Платежная система ЮMoney" src="@/static/features/yapay.png" />
      </div>
    </div>

    <div class="contact-info">
      <div class="email-wrapper">
        <i class="fas fa-envelope"></i>
        <span>
          Контактная почта:
          <a href="mailto:support@platishkin.ru">support@platishkin.ru</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeaturesPage",
  methods: {
    scrollToContent() {
      const element = this.$refs.stepsIntro;
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css");

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
  background: #0d0d0d;
  color: #e0e0e0;
  text-align: center;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  box-sizing: border-box;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Изменено с center на flex-start */
  align-items: center;
  min-height: 100vh;
  position: relative;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  font-size: 48px;
  cursor: pointer;
  color: #4a90e2;
  z-index: 99;
}

.back-button i {
  color: #4a90e2;
}

.header {
  position: relative; /* Добавлено для позиционирования стрелки */
  animation: fadeInDown 0.5s ease-in-out;
  margin-bottom: 30px;
  padding: 100px 20px;
}

.header h1 {
  font-size: 2.5rem;
  margin: 0 auto;
  color: #e0e0e0;
  font-weight: 600;
  letter-spacing: 1px;
  max-width: 800px;
}

.scroll-down-arrow {
  transform: translateX(-50%);
  position: absolute;
  bottom: 0;
  left: 50%;
}

.scroll-down-arrow i {
  font-size: 48px;
  color: #4a90e2;
  cursor: pointer;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(0, -15px);
  }
  60% {
    transform: translate(0, -7px);
  }
}

.steps-intro {
  animation: fadeIn 1s ease-in-out;
  margin-top: 40px;
}

.steps-intro h2 {
  font-size: 1.8rem;
  color: #4a90e2;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
  animation: fadeIn 1s ease-in-out;
}

.feature {
  background: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  width: calc(33.33% - 20px);
  box-sizing: border-box;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  text-align: center;
  position: relative;
  overflow: visible;
  transition: transform 0.3s ease;
}

.feature:not(.highlight):hover {
  transform: scale(1.05);
}

.feature h2 {
  font-size: 1.5rem;
  color: #4a90e2;
  margin-bottom: 10px;
}

.feature.highlight h2 {
  font-size: 2rem;
}

.feature.highlight p {
  font-size: 1.5rem;
}

.feature p {
  font-size: 1rem;
  color: #b0b0b0;
}

.feature img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-top: 10px;
}

.feature.highlight {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  box-shadow: none;
  overflow: visible;
}

.feature.highlight::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(
    to right,
    #ff0000,
    #ff8000,
    #ffff00,
    #00ff00,
    #00ffff,
    #0000ff,
    #8000ff,
    #ff0000
  );
  background-size: 200% 200%;
  animation: rainbow-border 3s linear infinite;
  z-index: 1;
  border-radius: 15px;
}

@keyframes rainbow-border {
  0% {
    background-position: 0 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

.feature.highlight::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background: #1a1a1a;
  z-index: 1;
  border-radius: 12px;
}

.feature.highlight > * {
  position: relative;
  z-index: 2;
}

.future-payments {
  text-align: center;
  margin: 50px 0;
  animation: fadeIn 1s ease-in-out;
}

.future-payments h2 {
  font-size: 1.8rem;
  color: #4a90e2;
  margin-bottom: 20px;
}

.payment-logos {
  background: #ffffff;
  border-radius: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.payment-logos img {
  height: 50px;
  margin: 10px;
  padding: 10px;
}

.contact-info {
  font-size: 1rem;
  color: #e0e0e0;
  animation: fadeIn 1.2s ease-in-out;
  margin-top: 40px;
  margin-bottom: 15px;
}

.email-wrapper {
  display: inline-flex;
  align-items: center;
  background: #1a1a1a;
  border: 2px solid #4a90e2;
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.email-wrapper .fa-envelope {
  font-size: 24px;
  margin-right: 10px;
  color: #4a90e2;
}

a {
  color: #4a90e2;
  text-decoration: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .feature {
    width: calc(50% - 20px);
  }

  .feature.highlight {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .feature {
    width: calc(100% - 40px);
  }

  .feature.highlight {
    width: 100%;
  }

  .back-button {
    font-size: 36px;
    top: 10px;
    left: 10px;
  }

  .header h1 {
    font-size: 1.8rem;
  }
}
</style>
