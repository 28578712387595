<template>
  <router-view />
</template>

<style>
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
  background: #0d0d0d; /* фон */
  color: #e0e0e0; /* текст */
  text-align: center;
  overflow-x: hidden;
}
</style>
