<template>
  <SuccessPage />
</template>

<script>
import SuccessPage from "@/components/SuccessPage.vue";

export default {
  components: {
    SuccessPage,
  },
};
</script>
