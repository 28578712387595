<template>
  <div class="success-page">
    <h1 class="title">🎉 Оплата успешно завершена! 🎉</h1>
    <p class="message">
      Спасибо за вашу покупку. Мы рады, что все прошло успешно.
    </p>
  </div>
</template>

<script>
export default { name: "SuccessPage" };
</script>

<style scoped>
.success-page {
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
}

.title {
  font-size: 2rem;
  color: #28a745;
  margin-bottom: 10px;
}

.message {
  font-size: 1.25rem;
  color: #6c757d;
  margin-bottom: 20px;
}

.confetti {
  width: 100px;
  height: auto;
}
</style>
