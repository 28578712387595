import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "./assets/telegram-web-app";
import { createPinia } from "pinia";

import "@fortawesome/fontawesome-free/css/all.css";

const pinia = createPinia();

createApp(App).use(router).use(pinia).mount("#app");
