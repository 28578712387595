import { createRouter, createWebHistory } from "vue-router";
import StoreView from "@/views/StoreView.vue";
import PayView from "@/views/PayView.vue";
import SuccessView from "@/views/SuccessView.vue";
import MainView from "@/views/MainView.vue";
import FeaturesView from "@/views/FeaturesView.vue";
import TGStoreView from "@/views/TGStoreView.vue";

const routes = [
  {
    path: "/",
    name: "MainPage",
    component: MainView,
  },
  {
    path: "/features",
    name: "Features",
    component: FeaturesView,
  },
  {
    path: "/store",
    name: "TGStorePage",
    component: TGStoreView,
  },
  {
    path: "/store/:id",
    name: "StorePage",
    component: StoreView,
    props: true,
  },
  {
    path: "/pay/:id",
    name: "PayPage",
    component: PayView,
    props: true,
  },
  {
    path: "/success",
    name: "SuccessPage",
    component: SuccessView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
