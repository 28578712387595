<template>
  <MainPage />
</template>

<script>
import MainPage from "@/components/MainPage.vue";

export default {
  components: {
    MainPage,
  },
};
</script>
