import { defineStore } from "pinia";

export const useItemsStore = defineStore("items", {
  state: () => ({
    selectedItems: [],
  }),
  actions: {
    addItem(item) {
      this.selectedItems.push(item);
    },
    removeItem(itemId) {
      this.selectedItems = this.selectedItems.filter(
        (item) => item.id !== itemId
      );
    },
  },
});
