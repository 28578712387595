<template>
  <div class="cart-container">
    <div class="cart-header">
      <h2>Корзина</h2>
      <a class="how-to-get" @click="goBack">Назад к выбору товаров &rarr;</a>
    </div>
    <div class="cart-items">
      <div v-for="item in selectedItems" :key="item.id" class="cart-item">
        <!-- Условное отображение изображения товара -->
        <div v-if="item.main_photo_url" class="item-image">
          <img
            :src="item.main_photo_url"
            alt="Product Image"
            class="product-image"
          />
        </div>
        <div class="item-more">
          <div class="item-details">
            <p class="item-name">{{ item.name }}</p>
            <div class="item-quantity-price">
              <div v-if="item.max_value > 1" class="item-quantity">
                <div
                  class="quantity-btn decrease"
                  @click="decreaseQuantity(item)"
                >
                  <span>−</span>
                </div>
                <div class="quantity">
                  <span>{{ item.quantity }}</span>
                </div>
                <div
                  :disabled="
                    item.quantity >= item.max_value && item.max_value > 0
                  "
                  class="quantity-btn increase"
                  @click="increaseQuantity(item)"
                >
                  <span>+</span>
                </div>
              </div>

              <p class="item-price">
                <span v-if="item.discount !== 0" class="original-price">
                  {{ item.price }} ₽
                </span>
                {{ discountedPrice(item.price, item.discount) }} ₽
              </p>
            </div>
          </div>
          <div class="remove-item">
            <button @click="removeItem(item)">Удалить</button>
          </div>
        </div>
      </div>
    </div>
    <div class="cart-summary">
      <div class="summary-details">
        <div class="summary-item">
          <p>Начальная цена</p>
          <p>{{ originalTotal }} ₽</p>
        </div>
        <div v-if="totalDiscount > 0" class="summary-item discount">
          <p>Ваша скидка</p>
          <p class="price">{{ totalDiscount }} ₽</p>
        </div>
        <div class="summary-item total">
          <p>Итого</p>
          <p>{{ finalTotal }} ₽</p>
        </div>
      </div>
    </div>
    <div class="cart-actions">
      <button v-if="!isLoading" class="checkout-btn" @click="makePayment">
        Оплатить
      </button>
      <div v-if="isLoading" class="loading-spinner"></div>
      <button v-if="!isLoading" class="continue-shopping-btn" @click="goBack">
        Вернуться к товарам
      </button>
    </div>
  </div>
</template>

<script>
import { useItemsStore } from "@/assets/store";
import axios from "axios";

export default {
  name: "CartPage",
  props: ["id"],
  data() {
    return {
      isLoading: false, // Флаг загрузки
    };
  },
  computed: {
    selectedItems() {
      const store = useItemsStore();
      return store.selectedItems;
    },
    originalTotal() {
      return this.selectedItems.reduce((total, item) => {
        return total + item.price * item.quantity;
      }, 0);
    },
    totalDiscount() {
      return this.selectedItems.reduce((total, item) => {
        const discount = (item.price * item.discount) / 100;
        return total + discount * item.quantity;
      }, 0);
    },
    finalTotal() {
      return this.originalTotal - this.totalDiscount;
    },
  },
  methods: {
    discountedPrice(price, discount) {
      const originalPrice = parseFloat(price);
      const finalPrice = originalPrice - originalPrice * (discount / 100);
      return finalPrice.toFixed(2);
    },
    increaseQuantity(item) {
      if (item.quantity < item.max_value || item.max_value === 0) {
        item.quantity++;
      }
    },
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        item.quantity--;
      }
    },
    removeItem(item) {
      const store = useItemsStore();
      store.selectedItems = store.selectedItems.filter((i) => i.id !== item.id);
    },
    async makePayment() {
      this.isLoading = true; // Начинаем загрузку
      const paymentData = {
        cart: {
          amount: {
            value: this.finalTotal.toFixed(2),
            currency: "RUB",
          },
          payment_method_data: {
            type: "bank_card",
          },
          confirmation: {
            type: "redirect",
            return_url: `${window.location.origin}/success`,
          },
          description: this.comment,
        },
      };

      try {
        const response = await axios.post(
          `/api/v1/bank_acquire/yoomoney/${this.id}/conformation_url`,
          paymentData
        );
        if (response.data.conformationURL) {
          window.location.href = response.data.conformationURL;
        } else {
          throw new Error("Не удалось получить URL для подтверждения.");
        }
      } catch (error) {
        console.error("Payment error:", error);
        alert(
          "Произошла ошибка при попытке оплаты. Пожалуйста, попробуйте снова."
        );
        this.isLoading = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.cart-container {
  background-color: #2b2b2b;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
}

.cart-header {
  text-align: center;
  margin-bottom: 20px;
}

.cart-header h2 {
  margin: 0 0 10px;
  font-size: 18px;
}

.how-to-get {
  color: #00aaff;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.cart-items {
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  margin: 10px 0;
  padding: 10px 0;
}

.cart-item {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
}

.item-image {
  width: 80px;
  height: 80px;
  background-color: #444;
  border-radius: 8px;
  margin-right: 10px;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.item-details {
  flex: 1;
  height: 80px;
}

.item-name {
  margin: 0;
  font-size: 14px;
  text-align-last: left;
  color: #fff;
}

.item-quantity-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}

.item-quantity {
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 8px;
  margin-right: 10px;
}

.item-quantity .quantity {
  height: 100%;
  border-top: 1px solid #777;
  border-bottom: 1px solid #777;
  background-color: #444;
  display: flex;
  align-items: center;
}

.item-quantity .decrease {
  height: 100%;
  border-radius: 8px 0 0 8px;
}

.item-quantity .increase {
  height: 100%;
  border-radius: 0 8px 8px 0;
}

.quantity-btn {
  border: 1px solid #777;
  background-color: #444;
  color: #fff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

.quantity-btn:disabled {
  background-color: #999; /* Цвет для отключенной кнопки */
  cursor: not-allowed;
}

.item-quantity span {
  background-color: #444;
  margin: 0 20px;
  font-size: 14px;
}

.item-price {
  margin: 0;
  font-size: 14px;
  text-align: right;
  display: flex;
  flex-direction: column;
}

.item-price .original-price {
  text-decoration: line-through;
  color: #888;
}

.remove-item button {
  background-color: transparent;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 14px;
}

.item-more {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cart-summary {
  margin: 10px 0;
}

.summary-details {
  font-size: 14px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.summary-item.discount .price {
  color: #31c471;
}

.summary-item.total {
  font-weight: bold;
  font-size: 16px;
}

.cart-actions {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.cart-actions button {
  background-color: #00aaff;
  border: none;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin: 5px;
}

.cart-actions button.continue-shopping-btn {
  background-color: #444;
}

.loading-spinner {
  border: 4px solid #f3f3f3; /* Светлый серый */
  border-top: 4px solid #00aaff; /* Синий */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
