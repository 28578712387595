<template>
  <StoreItems v-if="tgAppId" :id="tgAppId" />
</template>

<script>
import StoreItems from "@/components/StoreItems.vue";

export default {
  components: {
    StoreItems,
  },
  data() {
    return {
      tgAppId: "",
    };
  },
  mounted() {
    try {
      const tg = window.Telegram.WebApp;

      if (tg && tg.initDataUnsafe && tg.initDataUnsafe.start_param) {
        this.tgAppId = tg.initDataUnsafe.start_param;
      } else {
        console.error("start_param is not available in initDataUnsafe");
      }
    } catch (error) {
      console.error("Error fetching tgAppId:", error);
    }
  },
};
</script>
