<template>
  <StoreItems />
</template>

<script>
import StoreItems from "@/components/StoreItems.vue";

export default {
  components: {
    StoreItems,
  },
};
</script>
